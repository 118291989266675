// Shop Card
body .mat-card .mat-card-image {
	max-width: inherit;
	border-bottom: 1px solid #eee;

}

.m-icon {
	position: absolute;
	top: -49px;
	right: -5px;
	transition: all 0.3s ease-in;
}

.wishlist-icon {
  position: static;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-right: 30px;

}

.wishlist-icon-image {
  background: url("../../../assets/heart-black.png") no-repeat, center, center;
  background-size: 100%;
  width: 32px;
  height: 30px;
  position: relative;
}

.wishlist-icon-image:hover {
  background: url("../../../assets/heart-red.png") no-repeat, center, center;
  background-size: 100%;
  width: 32px;
  height: 30px;
}

.wishlist-icon-image:hover:after {
  content: "Add to Favorites";
  position: absolute;
  left: -44px;
  top: 20px;
  right: 0;
  bottom: 0px;
  width: 120px;
  height: 17px;
  z-index: 1;
  color: #333;
  text-align: center;
  font-family: Arial, sans-serif;
  font-size: 11px;
}

.wishlist-active .wishlist-icon-image:hover:after {
  content: "Remove from Favorites";

}

.wishlist-active .wishlist-icon-image {
  background: url("../../../assets/heart-red.png") no-repeat, center, center;
}


.wishlist-icon{
   position: absolute;
	top: 7px;
	right: 017px;
   z-index: 98;
   cursor: pointer;
	width: 50px;
   height: 50px;
   transition: all 0.3s ease-in 0s;
	text-align: center;
	line-height: 50px;
   a {
      color: rgba(0,0,0,0.4);
      &:hover{
         color: rgba(255, 0, 0, 0.7);
      }
   }
}

.fa-spinner{
  position: absolute;
  top: 10px;
  right: 017px;
}

.wishlist-active {
   will-change: font-size;
   animation: heart 1s;
   /*animation: heart 1s cubic-bezier(.17, .89, .32, 1.49);*/
}

.wishlist-icon.wishlist-active a{
  color: #474477;
}

@keyframes heart {
  0%, 15% { font-size: 0; }
}


mat-card:hover .m-icon {
	transform: translateY(-20px);
}

.no-card-content {
	padding-bottom: 0 !important;
}

.card-shadow {
	box-shadow: $card-shadow;
}

.shop-card-gap{
   margin-top: -120px;
   padding:1rem;
}
.section-title-toolbar{
   padding:1rem;
}

.section-title-toolbar {
	border-radius: 10px;
}
.box-shadow-md{
   &:hover{
         box-shadow: 0 0 16px 0 rgba(0,0,0,0.12), 0 16px 16px 0 rgba(0,0,0,0.24) !important;
         .button-grey {
            background: $accent;
            color: #fff;
          }
   }
}


