.user-nav-list {
  background: #474477;
  //box-shadow:$card-shadow;
  .mat-list-item-content a i {
    vertical-align: top;
    width: 40px;
    text-align: center;
  }
}

.user-nav-list a {
  font-size: 18px;
  font-weight: bold;
  color: #86849D;
}

.user-nav-list a:hover, .user-nav-list a.tab-active {
  color: white;
  img.custom-icons{
    mix-blend-mode: color-dodge;
  }

}


.user-nav-list a:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 0;
  left: -15px;
  background: #474477;

}

.user-nav-list a:hover:before, .user-nav-list a.tab-active:before {
  height: 25px;
}

.mat-expansion-panel {
  background: unset !important;
}

.proflie-field span {
  display: inline-block;
  width: calc(100% - 150px);
}

.proflie-field label {
  width: 150px;
  display: inline-block;
}

.mat-form-field-flex{
  align-items: center !important;
}

.small-arrow-down{
  height: 7px;
}

//login-user-img

.login-user-img img {
  border-radius: 100%;
}

.edit-profile {
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.2;
}
