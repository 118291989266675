.counter-item {
  display: inline-block;
  margin-right: 1.2rem;
  margin-bottom: 1.5rem;

  .counter-digit {
    font-size: $font-size-h1;
  }

  .counter-text {
    font-size: 1.25rem;
    display: inline-block;
    margin: 0 0.5rem;
  }
}

@media(max-width:560px) {


  .counter-item {
    display: inline-block;
    margin-right: 0.5rem;

    .counter-digit {
      font-size: 2rem;
    }

    .counter-text {
      font-size: 1rem;
      display: inline-block;
      margin: 0 0.2rem;
    }
  }
}
