/* $typography
 ------------------------------------------*/


a {
  text-decoration: none;
  color: inherit;
}

button {
  outline: 0;
}

figure {
  margin: 0;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
}

strong,
b {
  font-weight: 700;
}
