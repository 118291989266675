@import 'variables';
@import 'material-theme';
@import "core/core";
@import "components/components";
@import "utilities/utilities";
@import "rtl";


.loadscreen {
  text-align: center;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  top: calc(51% - 100px);

  .logo {
    display: inline-block !important;
  }

}

// LOADER-BUBBLE
.loader-bubble,
.loader-bubble:before,
.loader-bubble:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: bubble-horz 1.8s infinite ease-in-out;
  animation: bubble-horz 1.8s infinite ease-in-out;
}

.loader-bubble {
  font-size: 6px;
  margin: auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader-bubble {
  color: #474477;
}


.loader-bubble:before,
.loader-bubble:after {
  content: '';
  position: absolute;
  //bottom: 5px;
}

.loader-bubble:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader-bubble:after {
  left: 3.5em;
}

@-webkit-keyframes bubble-horz {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes bubble-horz {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

#docs-page {
  color: #333;
  font-size: 14px;

a {
  cursor: pointer;
}

#toc > li > div > a {
  display: block;
}

html body .content code span, html body .content pre span {
  font-family: monospace, serif !important;
  font-size: 12px;
  line-height: 1.5;
}

html body {
  color: #333;
}

table {
  font-size: 14px;
}

.content p {
  margin-bottom: 16px;
  color: #333;
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
dfn {
  font-style: italic;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 1em 40px;
}
hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}
pre {
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
}
optgroup {
  font-weight: bold;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6,
html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
}
.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
  font-weight: bold;
}
.content code,
.content pre {
  font-family: Consolas, Menlo, Monaco, "Lucida Console", "Liberation Mono",
    "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Courier New", monospace,
    serif;
  font-size: 12px;
  line-height: 1.5;
}
.content code {
  word-break: break-all;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.content aside.warning:before,
.content aside.notice:before,
.content aside.success:before,
.toc-wrapper > .search:before {
  font-family: "slate";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}
.content aside.warning:before {
  content: "\e600";
}
.content aside.notice:before {
  content: "\e602";
}
.content aside.success:before {
  content: "\e606";
}
.toc-wrapper > .search:before {
  content: "\e607";
}
html,
body {
  color: #333;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f7f9;
  height: 100%;
  -webkit-text-size-adjust: none;
}
#toc > ul > li > a > span {
  float: right;
  background-color: #2484ff;
  border-radius: 40px;
  width: 20px;
}
.toc-wrapper {
  -webkit-transition: left 0.3s ease-in-out;
  transition: left 0.3s ease-in-out;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  bottom: 0;
  width: 230px;
  background-color: white;
  font-size: 13px;
  font-weight: bold;
}
.toc-wrapper .lang-selector {
  display: none;
}
.toc-wrapper .lang-selector a {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.toc-wrapper .logo {
  display: block;
  max-width: 100%;
  margin-bottom: 0px;
  margin-left: auto;
  margin-top: 1rem;
  margin-right: auto;
  border-radius: 4px;
}
.toc-wrapper > .search {
  position: relative;
}
.toc-wrapper > .search input {
  background: #f7fafc;
  border-width: 1px;
  border-color: gainsboro;
  padding: 6px 0 6px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 10px 15px;
  width: 200px;
  outline: none;
  color: #697386;
  border-radius: 10px;
}
.toc-wrapper > .search:before {
  position: absolute;
  top: 19px;
  left: 21px;
  color: #959dab;
}
.toc-wrapper .search-results {
  margin-top: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 0;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-transition-property: height, margin;
  transition-property: height, margin;
  -webkit-transition-duration: 180ms;
  transition-duration: 180ms;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  background: #8fbcd4;
}
.toc-wrapper .search-results.visible {
  height: fit-content;
  margin-bottom: 1em;
}
.toc-wrapper .search-results li {
  margin: 1em 15px;
  line-height: 1;
}
.toc-wrapper .search-results a {
  color: #333;
  text-decoration: none;
}
.toc-wrapper .search-results a:hover {
  text-decoration: underline;
}
.toc-wrapper ul,
.toc-wrapper li {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 28px;
}
.toc-wrapper li {
  color: #fff;
  -webkit-transition-property: background;
  transition-property: background;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
}
.toc-wrapper .toc-link.active {
  background-color: #f5fbff;
  color: #7e6cd6;
}
.toc-wrapper .toc-link.active-parent {
  background-color: #f5fbff;
  color: #7e6cd6;
}
.toc-wrapper .toc-list-h2 {
  display: none;
  background-color: white;
  font-weight: 500;
}
.toc-wrapper .toc-h2 {
  padding-left: 25px;
  font-size: 12px;
}
.toc-wrapper .toc-footer {
  padding: 1em 0;
  margin-top: 1em;
  border-top: 1px dashed #666;
}
.toc-wrapper .toc-footer li,
.toc-wrapper .toc-footer a {
  color: #fff;
  text-decoration: none;
}
.toc-wrapper .toc-footer a:hover {
  text-decoration: underline;
}
.toc-wrapper .toc-footer li {
  font-size: 0.8em;
  line-height: 1.7;
  text-decoration: none;
}
.toc-link,
.toc-footer li {
  padding: 0 15px 0 15px;
  display: block;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
  color: #8792a2;
  -webkit-transition-property: background;
  transition-property: background;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: 130ms;
  transition-duration: 130ms;
}
#nav-button {
  padding: 0 1.5em 5em 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  color: #000;
  text-decoration: none;
  font-weight: bold;
  opacity: 0.7;
  line-height: 16px;
  -webkit-transition: left 0.3s ease-in-out;
  transition: left 0.3s ease-in-out;
}
#nav-button span {
  display: block;
  padding: 6px 6px 6px;
  background-color: rgba(243, 247, 249, 0.7);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotate(-90deg) translate(-100%, 0);
  transform: rotate(-90deg) translate(-100%, 0);
  border-radius: 0 0 0 5px;
}
#nav-button img {
  height: 16px;
  vertical-align: bottom;
}
#nav-button:hover {
  opacity: 1;
}
#nav-button.open {
  left: 230px;
}
.page-wrapper {
  margin-left: 230px;
  position: relative;
  z-index: 10;
  background-color: white;
  min-height: 100%;
  padding-bottom: 1px;
}
.page-wrapper .dark-box {
  width: 50%;
  background-color: white;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}
.page-wrapper .lang-selector {
  position: fixed;
  z-index: 50;
  border-bottom: 5px solid #8fbcd4;
}
.lang-selector {
  background-color: white;
  width: 100%;
  font-weight: bold;
}
.lang-selector a {
  display: block;
  float: left;
  color: dimgray;
  text-decoration: none;
  padding: 0 10px;
  line-height: 30px;
  outline: 0;
}
.lang-selector a:active,
.lang-selector a:focus {
  // background-color: #7ba5ba;
  // color: #fff;
}
.lang-selector a.active {
  // background-color: #8fbcd4;
  // color: #fff;
}
.lang-selector:after {
  content: "";
  clear: both;
  display: block;
}
.content {
  -webkit-transform: translateZ(0);
  position: relative;
  z-index: 30;
}
.content:after {
  content: "";
  display: block;
  clear: both;
}
.content > h1,
.content > h2,
.content > h3,
.content > h4,
.content > h5,
.content > h6,
.content > p,
.content > table,
.content > ul,
.content > ol,
.content > aside,
.content > dl {
  margin-right: 50%;
  padding-left: 28px;
  padding-right: 28px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
}
.content > ul,
.content > ol {
  padding-left: 43px;
}
.content > h1,
.content > h2,
.content > div {
  clear: both;
}
.content h1 {
  font-size: 25px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-bottom: 0px;
  margin-top: 2em;
  border-top: 1px solid #ccc;
  border-bottom: 0px solid #ccc;
  background-color: white;
}
// .content h1:first-child,
// .content div:first-child + h1 {
//   border-top-width: 0;
//   margin-top: 0;
// }
.content h2 {
  font-size: 19px;
  margin-top: 4em;
  margin-bottom: 0;
  border-top: 1px solid #ccc;
  padding-top: 1.2em;
  padding-bottom: 1.2em;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 0.2)),
    to(rgba(255, 255, 255, 0))
  );
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0)
  );
}
.content h1 + h2,
.content h1 + div + h2 {
  margin-top: -21px;
  border-top: none;
}
.content h3,
.content h4,
.content h5,
.content h6 {
  font-size: 15px;
  margin-top: 2.5em;
  margin-bottom: 0.8em;
}
.content h4,
.content h5,
.content h6 {
  font-size: 10px;
}
.content hr {
  margin: 2em 0;
  border-top: 2px solid #2e3336;
  border-bottom: 2px solid #f3f7f9;
}
.content table {
  margin-bottom: 1em;
  overflow: auto;
}
.content table th,
.content table td {
  text-align: left;
  vertical-align: top;
  line-height: 1.6;
}
.content table th code,
.content table td code {
  white-space: nowrap;
}
.content table th {
  padding: 8px;
  border-bottom: 1px solid #ccc;
  vertical-align: bottom;
}
.content table td {
  padding: 8px;
}
.content table tr:last-child {
  border-bottom: 1px solid #ccc;
}
.content table tr:nth-child(odd) > td {
  background-color: white;
}
.content table tr:nth-child(even) > td {
  // background-color: #fbfcfd;
}
.content dt {
  font-weight: bold;
}
.content dd {
  margin-left: 15px;
}
.content p,
.content li,
.content dt,
.content dd {
  line-height: 1.6;
  margin-top: 0;
}
.content img {
  max-width: 100%;
}
.content code {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 3px;
  border-radius: 3px;
}
.content pre > code {
  background-color: transparent;
  padding: 0;
}
.content aside {
  padding-top: 1em;
  padding-bottom: 1em;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  background: #8fbcd4;
  line-height: 1.6;
}
.content aside.warning {
  background-color: #c97a7e;
}
.content aside.success {
  background-color: #6ac174;
}
.content aside:before {
  vertical-align: middle;
  padding-right: 0.5em;
  font-size: 14px;
}
.content .search-highlight {
  padding: 2px;
  margin: -3px;
  border-radius: 4px;
  border: 1px solid #f7e633;
  background: -webkit-gradient(
    linear,
    right bottom,
    left top,
    from(#f7e633),
    to(#f1d32f)
  );
  background: linear-gradient(to top left, #f7e633 0%, #f1d32f 100%);
}
.content pre,
.content blockquote {
  background-color: #f7fafc;
  color: #697386;
  margin: 0;
  width: 50%;
  float: right;
  clear: right;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.content pre > p,
.content blockquote > p {
  margin: 0;
}
.content pre a,
.content blockquote a {
  color: #fff;
  text-decoration: none;
  border-bottom: dashed 1px #ccc;
}
.content pre {
  padding-top: 2em;
  padding-bottom: 2em;
  padding: 2em 28px;
}
.content blockquote > p {
  background-color: #e3e8ee;
  padding: 1em 2em;
  color: #4f566b;
}
@media (max-width: 930px) {
  .toc-wrapper {
    left: -230px;
  }
  .toc-wrapper.open {
    left: 0;
  }
  .page-wrapper {
    margin-left: 0;
  }
  #nav-button {
    display: block;
  }
  .toc-link {
    padding-top: 0.3em;
    padding-bottom: 0.3em;
  }
}
@media (max-width: 700px) {
  .dark-box {
    display: none;
  }
  .content > h1,
  .content > h2,
  .content > h3,
  .content > h4,
  .content > h5,
  .content > h6,
  .content > p,
  .content > table,
  .content > ul,
  .content > ol,
  .content > aside,
  .content > dl {
    margin-right: 0;
  }
  .toc-wrapper .lang-selector {
    display: block;
  }
  .page-wrapper .lang-selector {
    display: none;
  }
  .content pre,
  .content blockquote {
    width: auto;
    float: none;
  }
  .content > pre + h1,
  .content > blockquote + h1,
  .content > pre + h2,
  .content > blockquote + h2,
  .content > pre + h3,
  .content > blockquote + h3,
  .content > pre + h4,
  .content > blockquote + h4,
  .content > pre + h5,
  .content > blockquote + h5,
  .content > pre + h6,
  .content > blockquote + h6,
  .content > pre + p,
  .content > blockquote + p,
  .content > pre + table,
  .content > blockquote + table,
  .content > pre + ul,
  .content > blockquote + ul,
  .content > pre + ol,
  .content > blockquote + ol,
  .content > pre + aside,
  .content > blockquote + aside,
  .content > pre + dl,
  .content > blockquote + dl {
    margin-top: 28px;
  }
}
.highlight .c,
.highlight .cm,
.highlight .c1,
.highlight .cs {
  color: #909090;
}
.highlight,
.highlight .w {
  background-color: #f7fafc;
}

.toc-link {
  padding: 0;

  a {
    padding: 0 15px 0 15px;
    display: block;
  }
}

.toc-wrapper ul.search-results {
  margin-bottom: 0 !important;
}

ul.span-results.visible, ul.search-results.visible {
  background-color: #e3e8ee;
margin-bottom: 1em;
font-size: 12px;
// border-top: 1px solid transparentize(#333333, .66);;
// padding-top: 8px;
// padding-bottom: 8px;

  li {
    color: #333;
    padding: 7px 15px;
    font-weight: normal;
    word-wrap: break-word;
    line-height: 18px;
    // border-top: 1px solid #f1f3f6;
    cursor: pointer;
    // min-height: 32px;

    * {
      appearance: none;
      background-color: transparent;
      font-weight: inherit;
    }

    &.section {
      background-color: #f1f3f6;
      font-weight: bolder !important;
      text-transform: uppercase;
      letter-spacing: .01em;
      border-top: none;
      padding: 0;

      &.heading-2 {
        font-weight: normal !important;
        border-top: 1px solid #e3e8ee;
      }

      a {
        display: block;
        padding: 16px;
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }

    &:first-of-type{
      border-top: none;
    }

    span.search-highlight {
      font-weight: 800;
    }
  }

  .section + li {
    border-top: none;
  }
}

ul.search-results.visible {
  background-color: transparent;

  li {
    margin: 0;
  }
}

.content .docs-section {
  display: flex;
  flex-flow: row wrap;
  margin-top: 24px;
  width: 100%;

  &:first-child {
    border-top: 0;
  }

  &:last-child {
    margin-bottom: 32px;
  }

  & h1 + .docs-section{
    border-top: 0;
    margin-top: 0;
  }

  .main-col, .code-col {
    width: 50%;
    border-top: 1px solid #e3e8ee;
  }
}

.content pre, .content blockquote {
  float: none;
  width: 100%;
}

.content pre.highlight.tab-json, .content pre.highlight.tab-java {
// margin-top: -5px;
// margin-bottom: -17px;
margin-top: 7px;
// margin-bottom: 2px;
margin-bottom: -18px;

&:first-child {
  margin-top: -5px;
}
}

.page-wrapper .dark-box {
  // display: none !important;
}

.page-wrapper .lang-selector {
  position: fixed;
  right: 0;
  top: 0;
  width: calc((100% - 230px) / 2);
  background-color: #e3e8ee;
  border: 0;
  // border-bottom: 4px solid transparentize($color: #e3e8ee, $amount: 0);

  a {
    transition: all .1s ease;
    font-weight: 900;
    text-transform: uppercase;
    color: #8792a2;
    font-size: 12px;
    line-height: 32px;
    padding: 0 16px;
    padding-top: 4px;
    border-bottom: 4px solid transparent;
    letter-spacing: .05em;
    transition: all .1s ease;

    &.active {
      border-bottom-color: #333;
      background-color: transparent;
      color: #333;
    }
  }
}

.toc-wrapper .lang-selector {
margin-bottom: 16px;
  background-color: #e3e8ee;

  a {
    transition: all .1s ease;
    font-weight: 900;
    text-transform: uppercase;
    color: #8792a2;
    font-size: 12px;
    line-height: 32px;
    padding: 0 16px;
    padding-top: 4px;
    border-bottom: 4px solid transparent;
    letter-spacing: .05em;
    transition: all .1s ease;

    &.energize-focus {
      background-color: transparent !important;
    }

    &.active {
      border-bottom-color: #333;
      background-color: transparent;
      color: #333;
    }
  }
}

.content *:last-child {
  margin-bottom: 0;
}

.code-col-bkg {
  position: fixed;
  right: 0;
  top: 0;
  width: calc((100% - 230px) / 2);
  height: 100vh;
  // background-color: #333;
  background-color: transparentize($color: #e3e8ee, $amount: 0.5);
}

.content {

  h1, h2 {
    margin-top: 0;
    padding-top: 16px;
    padding-bottom: 12px;
    margin-bottom: 0;
    border-top: none;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    background-color: transparent;
    background-image: none;
  }

  h1 {
    font-size: 32px;
    font-weight: 900;
  }

  h2 {
    padding-top: 24px;
    // padding-bottom: 24px;
    // font-weight: 400;
    font-weight: 900;
    font-size: 20px;
    // font-weight: 300;
  }

  h3 {
    // font-size: 20px;
    font-size: inherit;
    font-weight: 400;
    margin-top: 16px;
    margin-bottom: 12px;
    letter-spacing: .05em;
    text-transform: uppercase;
  }
  }

  .content aside {
    font-weight: bold;
      color: white;
      position: relative;

      &:before {
        display: none;
    font-size: 24px;
    padding-top: 4px;
    position: absolute;
    left: 0;
    top: 0;

      }

    &.success {
      background-color: #00c652;
      background-color: #e8f5e9;
      color: #00b248;
      color: #43a048;
    }

    &.notice {
      background-color: #2195f2;
      background-color: #e2f6ff;
      color: #1c87e5;
    }
  }

  .content h1:last-child {
    padding-bottom: 0;
  }

  .content .docs-section h1 + .docs-section h2 {
    padding-top: 8px;
  }

  * {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  }

  pre, code {
    font-family: 'DM Mono', monospace !important;
    font-weight: 500;
     * {
      font-family: 'DM Mono', monospace !important;
      font-weight: 500;
     }
  }

  .content .code-col {
    border-top: 1px solid #e3e8ee !important;

    * {
      background-color: transparent;
      // color: #ccc;
    }

    blockquote > *, pre {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      padding-left: 16px;
      padding-right: 16px;
      // margin-top: 12px;
      margin-top: 24px;
    }

    blockquote:first-child > *:first-child  {
      margin-top: 12px;
    }

    pre.tab-shell:first-child {
      margin-top: 12px !important;
    }

    blockquote * {
      font-weight: 800;
    }


    &.empty {
border-top: none !important;
    }
  }

  .docs-section + .main-col, .docs-section + .main-col + .code-col {
    margin-top: 32px;
  }

  .content .main-col {

    *:first-child {
      margin-top: 12px;
    }

    & > * {
      padding-left: 16px;
    padding-right: 16px;
    }
  }

  .content {
    blockquote > p, aside {
    padding: 16px !important;
    }

    .main-col * code {
      padding-left: 8px;
      padding-right: 8px;
    }

  table {
    // min-width: 100%;
    width: calc(100% - 32px);
    margin: auto;
    border-top: 1px solid transparentize(#ccc, 0.66);

    tr, tr:last-child {
      border-bottom: 1px solid transparentize(#ccc, 0.66);
    }

    th {
      white-space: nowrap;
      border: none;
      font-weight: 800;
    }

    tr td:first-child, tr th:first-child {
      padding-left: 0px;
    }

    tr td:last-child, tr th:last-child  {
      padding-right: 0px;
    }
  }
  }

  .toc-wrapper {
    background-color: #f7fafc;
    // background-color: transparentize($color: #e3e8ee, $amount: 0.5);
    background-color: #f1f3f6;

    img.logo{
      display: none;
    }

    & > .search input {
      background: transparentize($color: #e3e8ee, $amount: 0);
      // background-color: white;
      // border: 1px solid #e3e8ee;
      padding: 0px;
      padding-left: 28px;
      padding-right: 40px;
      height: 40px;
      border: 0;
      color: #333;
font-weight: 800;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      border-radius: 0;
      padding-left: 36px;
      margin-top: 0;
      margin-bottom: 0;

      &::placeholder {
        color: #8792a2;
      }
    }

    #btn-search-reset {
      position: absolute;
      right: 0;
      top: 0;
      width: 40px;
      height: 40px;
      transition: all .1s ease-in-out;
      opacity: 0;

      svg {
        fill: #8792a2;
        width: 24px;
        margin-top: 8px;
        margin-left: 8px;
      }

      &:hover svg {
        fill: #697386;
      }

      &:active svg {
        fill: #333;
      }
    }

    #btn-search-reset.show {
      opacity: 1;
    }

    & > .search:before {
      top: 14px;
      left: 24px;
      color: #8792a2;

      left: 16px;
    }

    .span-results {
      margin-bottom: 16px !important;
    }

    .toc-link {
      position: relative;
    }

    .toc-link > a {
      // color: #4f566b;
      font-weight: 800;
      font-size: 12px;
      padding-top: 2px;
    padding-bottom: 2px;

      &::before {
        content: '';
        // width: 4px;
        // border-radius: 0 4px 4px 0;
        // width: 6px;
        // border-radius: 0 6px 6px 0;
        // width: 8px;
        // border-radius: 0 4px 4px 0;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: transparent;
        transition: background .1s ease-in-out;
      }
    }

    .toc-link.active > a::before {
      background-color: #333;
      // background-color: #ff5722;
    }

    .toc-link.active-parent, .toc-link.active {
      // background-color: white;
      // background-color: transparent;
      background-color: #e3e8ee;
      color: #333;
      a {
        // color: #ff5722;
        // color: #333;
        color: inherit;
        transition: .1s color ease-in-out;
      }
    }

    .toc-list-h2 {
      // background-color: transparent;
      background-color: #e3e8ee;
      // border-top: 1px solid #e3e8ee;
      // border-bottom: 1px solid #e3e8ee;
    }
  }

  @media screen and (max-width: 930px) {
    .code-col-bkg, .page-wrapper .lang-selector {
      width: 50%;
    }
  }

  @media screen and (max-width: 700px) {

    .code-col-bkg {
      display: none;
    }

.content .docs-section {

  .main-col, .code-col {
    width: 100%;
  }

  h1, h2 {
    order: 0;
    padding-bottom: 20px;
  }

  .main-col {
    order: 3;
    border: 0;

    *:first-child {
      margin-top: 0;
    }
  }

  .code-col {
    order: 2;
    border: 0;
    margin-bottom: 16px;
    background-color: transparentize($color: #e3e8ee, $amount: 0.5);
    border-top: none !important;
    padding-bottom: 16px;
    margin-top: 0;

    &.empty {
      display: none;
    }

    blockquote:first-child > *:first-child, pre {
    margin-top: 16px;
    }

    pre:first-child {
      margin-top: -1px;
    }
  }
}

.content .docs-section h1 + .docs-section h2 {
  padding-top: 0;
}

.docs-section + .main-col, .docs-section + .main-col{
  margin-top: 0px;

  & + .code-col {
    margin-top: 20px;
  }
}

  }
}
