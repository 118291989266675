/* You can add global styles to this file, and also import other style files */

* {
  font-family: Roboto, sans-serif;
}

.mat-drawer-container {
  background-color: white !important;
}

.mat-sidenav-content {
  display: flex !important;
  min-height: 100vh !important;
  flex-direction: column !important;
}

.main {
  flex: 1 !important;
}

h1, h2, h3, h4, h5, p {
  margin: 0;
}

.no-padding {
  padding: 0 !important;
}

.visible {
  visibility: visible !important;
  opacity: 1 !important;
}


.moreBrands {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  border: 0;
  width: 100%;
  font-weight: bold;
  padding: 15px;
  margin: 0 auto;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: #474477;
}

.sort-class{
  display: flex;
  margin-bottom: 5px;
  flex-direction: column;
}
.status-class{
  padding: 7px 0;
  display: flex;
  justify-content: center;
  border-radius: 3px;
}
.status-class{
  padding: 7px 0;
  display: flex;
  justify-content: center;
  border-radius: 3px;
}
.header-title{
  font-weight: bold;
  color: black;
  font-size: 14px;
}
.moreBrands:hover {
  background: #5B6BB6;
  color: #fff;
  transition: 200ms;
}

.moreBrands:disabled{
  border:1px solid #ddd;
  color: #dddddd;
  cursor: not-allowed;
  background: #fff;
}
.moreBrands:disabled:hover{
  border:1px solid #ddd;
  color: #dddddd;
  background: #fff;
}

.wide {
  padding: 12px 32px;
}

.addOrgan {
  max-width: 100%;
  margin: 0;
  background: #fff;
  width: 100%;
}


.joinNow {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  border: 1px solid #474477;
  max-width: 170px;
  padding: 12px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #474477;
  border-radius: 4px;
  font-weight: bold;
  margin-left: 16px;

}


.joinNow:hover {
  background: #fff;
  color: #474477;
  font-weight: bold;
}

.loading-indicator ::ng-deep .mat-progress-spinner circle, .mat-spinner circle {
  stroke: #474477 !important;
}

.loading-indicator {
  z-index: 1;
  position: fixed;
  top: 50%;
  left: 50%;
}

.loading-indicator:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.2);
}

.mat-toolbar .mat-input-element {
  font-size: 20px;
}


.app-nav .app-nav-list > li a.main-links {
  padding: 30px 10px !important;
  line-height: 30px !important;
  letter-spacing: 0.4px;
}

.app-nav .app-nav-list > li a {
  padding: 15px !important;
  line-height: 15px !important;
}

.custom-sub-menu {
  padding: 25px !important;
}

.home-main-banner {
  /*background: #fafafa!important;*/
  min-height: auto !important;
}


.mat-elevation-z8, .box-shadow-none {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}

.formRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.customRow {
  display: flex;
  justify-content: space-between;
}


.rowAround {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.rowEvenly {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.jqx-chart-title-text {
  display: none;
}

.pointer {
  cursor: pointer;
}

.pointer:hover {
  opacity: .6;
}

.pointer2 {
  cursor: pointer;
}
.pointer3 {
  cursor: pointer;
  opacity: .6;
}
.pointer3:hover{
  opacity: 1;
}
.pointer3 a:hover{
  opacity: 1!important;
}
.delete-button {
  background: transparent;
  border: 0;
  font-weight: bold;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  padding: 0;
  color: #333;
}

.delete-button:hover {
  color: #474477;
}

.delete-button:disabled {
  opacity: 0.5;
}

.delete-button:disabled:hover {
  color: #333;
  cursor: not-allowed;
}
.custom-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.custom-container-small {
  max-width: 585px;
  padding: 0 20px;
  /*margin-left: 92px;*/
}

.title-class{
  color: #474477;
}

@media screen and (max-width: 1330px) {
  .custom-container {
    padding: 0 10px;
  }

}

@media screen and (max-width: 1024px) {

  .custom-container-small {
   margin: 0 auto;
    padding: 0 10px;
  }

  .app-nav .app-nav-list > li a.main-links {
    padding: 10px 10px !important;
    line-height: 20px !important;

  }

  .app-nav .app-nav-list > li a.main-links.active-link {
    border-left: 3px solid #474477;
  }

  .app-nav .app-nav-list > li.active-link:after {
    display: none;
    width: 0!important;
    opacity: 0;
  }

  .app-nav .app-nav-list > li {
    float: none!important;
  }
}

