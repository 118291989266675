//Quick Sales Section
.overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  * {
    color: #fff;
  }
}

.overlay-wrap {
  position: relative;
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: rgba($color, $opacity);
}

.quick-shop {

  .primary-rgba,
  .pink-rgba {
    h2 {
      font-weight: 900;
      font-size: 6rem;
      font-style: italic;
      line-height: 1;
    }

    h5 {
      letter-spacing: 2px;
      text-transform: uppercase;
      font-weight: 500;
      line-height: 1;
    }

    h3 {
      font-weight: bolder;
      line-height: 1;
    }
  }

  .black-rgba {
    h6 {
      font-size: 1.5rem;
      text-transform: uppercase;
      letter-spacing: 4px;
      font-weight: 500;
      line-break: 1;
    }

    h2 {
      font-size: 6rem;
      font-weight: 400;
      line-height: 1;
    }

    h5 {
      letter-spacing: 5px;
      text-transform: uppercase;
      line-height: 1;
    }
  }
}
@media(max-width:991px){
   .cta-content h2 {
      font-size: 38px;
      line-height: 39px;
   }
   .download-app-list:first-child{
      border:none !important;
   }
}

@media(max-width:767px){
   .cta-content h4 {
      display: none;
   }
}

@media(max-width:478px){
   .cta-content i {
      display: none;
   }
   .cta-content h2 {
      font-size: 21px;
      line-height: 23px;
   }
}

