//Scaffolding

html,
body {
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html body {
	margin: 0;
  color: black;

}
.mat-toolbar, .mat-toolbar h1, .mat-toolbar h2, .mat-toolbar h3, .mat-toolbar h4, .mat-toolbar h5, .mat-toolbar h6 {
	font-family: $mat-font-family;
}
 

[tabindex='-1']:focus {
	outline: none;
}

body {
	.mat-card {
      border-radius: 5px;
      overflow: hidden;
	}
}

select,
button,
textarea,
input {
	vertical-align: baseline;
}
